
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600&family=Raleway:wght@100&display=swap');

.contact-container {
  margin-bottom: 60px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay2{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 143, 153, 0.322);
  }



.contact-image-container {
  position: relative;
  max-width: 1200px;

  
}

.contact-image {
  width: 1143px;
  height: 450px;

  display: block;
  max-width: 1200px;
  
}

.email-button {

  font-family: Poppins, sans-serif;
  font-size: 18px;
    
  color: white;
  width: 762px;
  height: 50px;

  background: rgba(217, 217, 217, 0.60);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  border: none;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.1ms;
}

.wpp-button {

  font-family: Poppins, sans-serif;
  font-size: 18px;    
  color: white;
  width: 762px;
  height: 50px;
 
  background: rgba(217, 217, 217, 0.60);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  border: none;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.1ms;
 
}

.wpp-button:hover{
    color: #9F1880;
    cursor: pointer;
}

.email-button:hover{
    color: #9F1880;
    cursor: pointer;
}

.whatsapp-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.contact-responsive{
  display: none;
}


.text-over-image {
  position: absolute;
  top: 50px; /* Ajusta la posición vertical según tu diseño */
  left: 90px; /* Ajusta la posición horizontal según tu diseño */
  color: white; /* Color del texto */
  font-size: 40px; /* Tamaño del texto */
  font-family: Poppins, sans-serif;
}



.iconContacto {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 60px; /* Ajusta la posición vertical según tus necesidades */
  
}


.iconContacto, .iconContactologo box-icon{
 fill: #ffffff;
}

.pContacto {
  color: #ffffff;
  margin-top: -18px;
 
}
.id{
  height: 90px;
}
@media screen and (max-width: 768px) {

 #contacto{
  margin: 0;
    padding: 0;
   width: 100%;
 }
  .contact-container {
    display: none;
  }
  .a-responsive {
    color: rgb(255, 255, 255);

  }
  
  .contact-responsive{
    padding-top: 20px;
    display: block;
    background-color: #9F1880;

    margin: 15px;
    height: 300px;
  }

  .ul-responvice, .li-responsive{
    font-size: 16px;
    font-family: Poppins, sans-serif;
    list-style-type: none;
    color: white;
    margin-top: 20px;
  }
  .wpp-button-responsive{
    background-color:  #05A5B2;
   
    border: none;
    width: 130px;
    color: white;
    margin: 5px;
    padding: 10px;
  }
  .email-button-responsive{
    background-color:  #05A5B2;
  
    border: none;
    width: 130px;
    color: white;
    margin: 5px;
    margin-top: 40px;
    padding: 10px;
  }
}
