@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Raleway:wght@100&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

:root {
	--mainColor: #05A5B2;
	--mainColorLight: #5767aa;
	--secondaryColor: #9F1880;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
    position: fixed;
    z-index: 1000;
    width: 100%;
    
}
.custom-list{
	margin-top: 50px;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
    cursor: pointer;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}


/* Estilos del menú desplegable */
.menu-vertical {
	
	position: absolute;
	background-color: #05A5B2;
	z-index: 1000;
	top: 100%;
	left: 0;
	padding: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	list-style: none; /* Eliminar viñetas de la lista */
	margin: 0;
  }
  
  .menu-vertical li {
	list-style: none; 
	padding: 10px 20px; /* Espaciado interno */
	text-align: start;
	cursor: pointer; /* Cambiar el cursor a pointer */
  }
  
  .subtitulo{
	text-align: center;
	font-size:14px;
  }
  /* Cambiar el fondo al pasar el cursor */
  .menu-vertical li:hover {
	 color:  var(--secondaryColor);
  }
  
  /* Estilos para el dropdown */
  .dropdown {
	position: relative;
  }
  
  .dropdown a {
	text-decoration: none;
	padding: 10px;
	
  }
  
  /* Cambiar el cursor al pasar sobre el enlace */
  a.aProfesionales, a.aTramites {
	cursor: pointer;
	padding: 10px;
  }
  

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		right: 0;
		height: 70%;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.2rem;
	}

	.style-li ul li {
		text-decoration: none;
	}

    .menu-vertical{
		margin-top:0px;
	
		margin-left: 2px;
		
	}
}

.custom-list {
  
  background-color: var(--mainColor);
  width: auto;
  white-space: nowrap;
 list-style-type: none;
   cursor: pointer;
  
  
}

.custom-list li {
  padding-left: 10px; 
  cursor: pointer;
}
