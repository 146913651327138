

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Raleway:wght@,100;1,500;1,400&display=swap');


 h2 {
    font-family: Poppins, sans-serif;
    color: #9F1880;
   
}



 h3 {
    font-family: Raleway;
}

p {
    font-family: Raleway;
    font-weight: 600;
    color: #000;
    font-size: 20px;
    text-align: justify;
    padding: 20px;
}

.institucional-container {
  padding: 70px;
  margin:auto; 
  padding-right: 150px;
  padding-left: 150px;
  line-height: 25px;
  font-size: 18px;
  
}

.image-container {
  display: flex;
  justify-content: space-between;
  width: 350px; 
  margin-left: 70px;
}

.zoom-image {
  padding: 15px;
  width: 350px; /* Tamaño inicial de la imagen */
  transition: transform 0.3s; /* Transición suave de 0.3 segundos */
}

.zoom-image:hover {
  transform: scale(1.2); /* Efecto de aumento al pasar el ratón */
}


.btn1 {
width: 100px;
height: 30px;
padding: 10px;
justify-content: center;
border: 2px solid;
border: none;

margin-left: 450px;
margin-right: 150px;



font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
transition: background-color 0.5s ease;
}

.btn1:hover {
  background-color: #ffdffb; /* Cambia el color de fondo al pasar el ratón */
}

.btn-container{
  display: flex;


}

.btn2 {
width: 100px;
height: 30px;
padding: 10px;
justify-content: center;
border: 2px solid;
border-color: #9F1880;
border-radius: 10px;
margin-right: 100px;

color: #9F1880;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
transition: background-color 0.5s ease;
}


.btn2:hover {
  background-color: #ffdffb;; /* Cambia el color de fondo al pasar el ratón */
 
}

.btn-LE{
background: #05A5B2;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
border: none;
color: white;
padding: 10px;
margin: 10px;
width: 600px;
transform: 1.0;
font-size: 18px;
}

.btn-LE p {

  
}

.btn-LE:hover{
  color: #9F1880;
  cursor: pointer;
}

@media (max-width: 767px) {
 .institucional-container {
    padding: 10px;
    margin: 0;
   
        
  }
  .btn-LE {
    width: 100%; /* Ancho completo en dispositivos móviles */
    margin: 10px 0;
  
  }

  /* Ajusta el tamaño de fuente para dispositivos móviles */
  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
    text-align: justify;
    padding: 20px;
    text-align: center;
     line-height: 1.5;
    
  }
}