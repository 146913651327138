@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Raleway:wght@100,500,600,400&display=swap');



.container {
  
 margin: 20px;
 margin-top: 0;
}



.subtitle{
  text-align: center;
}

.nombre {
  color: #9F1880;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.fila1 p, .fila1 h3{
  display: flex;
  justify-content: center;
  padding-top: 0;
  margin: 0px;
  margin-right: 10px;
  margin-left: 10px;
}


.fila2 {
  display: inline-block;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
 
}

.fila2 p, .fila2 h3{
  padding-top: 0;
  margin: 0px;
  margin-right: 10px;
  margin-left: 10px;
}


.fila3{
  display: inline-block;
  justify-content: center;
  margin-bottom: 25px;
}

.fila3 p, .fila3 h3{
  padding-top: 0;
  margin: 0px;
  margin-right: 10px;
  margin-left: 10px;

}

.fila3 p, .fila2 p{
  text-align: center;
}









